import _Feature from 'src/types/gis/feature';

export default class Store {
  private static instance: Store;

  MapView?: __esri.MapView;

  communityGeometry?: __esri.Geometry;

  // private constructor() {
  // }

  public static getInstance(): Store {
    if (!Store.instance) {
      Store.instance = new Store();
    }
    return Store.instance;
  }
}
