import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { communityRoutes } from './community';
import { managementRoutes } from './management';

const PageHome = lazy(() => import('src/pages/homePage'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <PageHome />,
    },

    // Auth routes
    ...authRoutes,

    // community routes
    ...communityRoutes,

    // Management routes
    ...managementRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
