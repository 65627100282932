import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageUserAccount = lazy(() => import('src/pages/user/account'));
const PageCommunityAccount = lazy(() => import('src/pages/community/profile'));

// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: 'management',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/management/user" replace />, index: true },
      {
        path: 'user',
        children: [
          { element: <Navigate to="/management/user/account" replace />, index: true },
          { path: 'account', element: <PageUserAccount /> },
        ],
      },
      {
        path: 'community',
        children: [
          { element: <Navigate to="/management/community/profile" replace />, index: true },
          { path: 'profile', element: <PageCommunityAccount /> },
        ],
      },
    ],
  },
];
