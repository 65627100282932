import { createSlice } from '@reduxjs/toolkit';
import { VISITOR_STATUS } from 'src/enum/auth';
import { COMMUNITYMEMBER } from 'src/enum/community';
import { GENDER, USER_STATUS } from 'src/enum/user';
import { ADDRESS_VERIFICATION_STATUS, VERIFICATION_STATUS } from 'src/enum/verification';
import { IAuth } from 'src/types/app/auth';

const userInitialState = {
  communityRole: COMMUNITYMEMBER.NotSet,
  displayName: '',
  email: '',
  id: '',
  gender: GENDER.UnSet,
  isEmailVerified: VERIFICATION_STATUS.NotVerified,
  mailBoxVerificationStatus: ADDRESS_VERIFICATION_STATUS.NotVerified,
  isPhoneVerified: VERIFICATION_STATUS.NotVerified,
  profilePic: '',
  status: USER_STATUS.UnSet,
};

const initialState: IAuth = {
  user: {
    ...userInitialState,
  },
  visitor_status: VISITOR_STATUS.Anonymous,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.user = action.payload;
    },
    updateState(state, action) {
      state.visitor_status = action.payload.vistorStatus;
      state.user = action.payload.user;
    },
    setStatus(state, action) {
      state.visitor_status = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
