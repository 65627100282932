import { useEffect, useCallback, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

//
import { IAuth } from 'src/types/app/auth';
import { VISITOR_STATUS } from 'src/enum/auth';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const auth: IAuth = useSelector((state: any) => state.auth);
  const router = useRouter();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (auth.visitor_status === VISITOR_STATUS.Anonymous) {
      searchParams.append('returnTo', window.location.pathname);
      const loginPath = loginPaths.jwt;

      const href = `${loginPath}?${searchParams.toString()}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [auth.visitor_status, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
