// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export const isValidToken = (expirationTime: number) => {
  const currentTime = Date.now() * 1;
  return expirationTime > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  const currentTime = Date.now() * 1;
  if (exp < currentTime) {
    clearSessions();
  }

  const remainingTime = exp - currentTime;
  const expiredTimer = setTimeout(() => {
    alert('Session expired');
    clearSessions();
    clearTimeout(expiredTimer);
    window.location.href = paths.auth.jwt.login;
  }, remainingTime);
};

// ----------------------------------------------------------------------
export const clearSessions = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('expirationTime');
};

// ----------------------------------------------------------------------
export const setSession = (
  accessToken: string | null,
  expTime: number,
  setStorage: boolean = true
) => {
  if (accessToken) {
    if (setStorage) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('expirationTime', `${expTime}`);
    }

    axios.defaults.headers.common.Authorization = `${accessToken}`;

    tokenExpired(expTime);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};
