import { useCallback, useEffect } from 'react';
// redux
import { useSelector } from 'react-redux';
// routes
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { IAuth } from 'src/types/app/auth';
import { VISITOR_STATUS } from 'src/enum/auth';
import { PATH_AFTER_LOGIN } from 'src/config-global';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || PATH_AFTER_LOGIN;

  const auth: IAuth = useSelector((state: any) => state.auth);
  const check = useCallback(() => {
    if (auth.visitor_status === VISITOR_STATUS.Authenticated) {
      let href = returnTo;
      if (searchParams) {
        searchParams.delete('returnTo');
        href = `${returnTo}?${searchParams.toString()}`;
      }

      router.replace(href);
    }
  }, [auth.visitor_status, returnTo, router, searchParams]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
