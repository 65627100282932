import { useState, memo, useCallback } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
// utils
import axios from 'src/utils/axios';
import gisDraw from 'src/utils/gis/draw';
import { stringToaddress } from 'src/utils/format-address';
// types
import { _Point } from 'src/types/gis/common';
import { ICommunitySearch } from 'src/types/app/community';
// Data
import Store from 'src/data/store';
//
import ResultItem from './result-item';
import { groupedData } from './utils';

// ----------------------------------------------------------------------

interface IProps {
  selectClickHandler?: (communityID: string, pCommunityTitle: string) => void;
}
interface SuggestionItem {
  group: string;
  title: string;
  path: string;
  indexKey: string;
  location?: _Point;
}
// ----------------------------------------------------------------------
const store = Store.getInstance();

function Searchbar({ selectClickHandler }: IProps) {
  const [suggestions, setSuggestions] = useState<SuggestionItem[]>([]);

  const theme = useTheme();

  const search = useBoolean();

  const mdUp = useResponsive('up', 'md');

  const [searchQuery, setSearchQuery] = useState('');

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (path: string, location: _Point, indexKey: string, title: string) => {
      const getLatLong = async () => {
        const address = stringToaddress(path);
        if (address) {
          const response = await axios.post(`/Gis/Address/Geocode`, {
            line1: address.line1,
            city: address.city,
            state: address.state,
            country: address.country,
            zipCode: address.zipCode,
          });

          if (store.MapView && response.data && response.data.coordinates) {
            const coordinates = response.data.coordinates;
            gisDraw._DrawLatLong(
              store.MapView,
              [{ latitude: coordinates.latitude, longitude: coordinates.longitude }],
              undefined,
              { zoomLevel: 2, clearGraphics: true }
            );
          }
        }
      };
      handleClose();
      if (location) {
        // store;
        if (store.MapView) {
          gisDraw._DrawLatLong(
            store.MapView,
            [{ latitude: location.latitude, longitude: location.longitude }],
            undefined,
            { zoomLevel: 2, clearGraphics: true }
          );
          if (selectClickHandler) {
            selectClickHandler(indexKey, title);
          }
        }
      } else {
        getLatLong();
      }
    },
    [handleClose, selectClickHandler]
  );

  const handleSearch = useCallback(async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const items: SuggestionItem[] = [];
    setSearchQuery(event.target.value);
    if (event.target.value) {
      const response = await axios.post(`/Community/Search/Address`, {
        text: event.target.value,
        inCommunities: true,
        inAddress: true,
      });
      const { isOk, message, exception } = response.data;
      if (isOk) {
        response.data.communityItems
          .filter(
            (x: ICommunitySearch) => x.location && x.location.latitude && x.location.longitude
          )
          .forEach((item: ICommunitySearch) => {
            items.push({
              group: 'Community',
              title: item.title,
              path: `${item.address.line1}, ${item.address.city}, ${item.address.state}, ${item.address.country}`,
              indexKey: `${item.communityId}`,
              location: { latitude: item.location.latitude, longitude: item.location.longitude },
            });
          });

        response.data.addressItems.forEach((item: { address: string }) => {
          const addressParts = item.address.split(',');
          if (addressParts.length > 1) {
            items.push({
              group: 'Address',
              title: addressParts[0],
              path: item.address.replace(`${addressParts[0]}, `, ''),
              indexKey: item.address,
            });
          }
        });
      }
    }
    setSuggestions(items);
  }, []);

  const notFound = searchQuery && !suggestions.length;

  const renderItems = () => {
    const data = groupedData(suggestions);

    return Object.keys(data)
      .sort((a, b) => -b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item: any) => {
            const { title, path, location, indexKey } = item;

            const partsTitle = parse(title, match(title, searchQuery));

            const partsPath = parse(path, match(path, searchQuery));

            return (
              <ResultItem
                path={partsPath}
                title={partsTitle}
                key={`${title}${path}`}
                groupLabel={searchQuery && group}
                onClickItem={() => {
                  console.log(partsTitle);
                  handleClick(
                    `${partsTitle.map((x) => x.text).join()}, ${path}`,
                    location,
                    indexKey,
                    title
                  );
                }}
              />
            );
          })}
        </List>
      ));
  };

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
