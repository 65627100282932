import { configureStore } from '@reduxjs/toolkit';
// slices
import userAccountSlice from './userAccount/userAccountSlice';
import uiSlice from './ui/ui-slice';
import communitySlice from './community/community-slice';
import authSlice from './auth/auth-slice';

const store = configureStore({
  reducer: {
    userAccount: userAccountSlice,
    ui: uiSlice,
    community: communitySlice,
    auth: authSlice,
  },
});

export default store;
