// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  COMMUNITY: '/community',
  MANAGEMENT: '/management',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
      verifyPassword: `${ROOTS.AUTH}/verify`,
      confirmEmail: `${ROOTS.AUTH}/confirmation`,
    },
  },
  // COMMUNITY
  community: {
    root: ROOTS.COMMUNITY,
    timeline: `${ROOTS.COMMUNITY}/timeline`,
    join: `${ROOTS.COMMUNITY}/join`,
    create: `${ROOTS.COMMUNITY}/create`,
  },
  // COMMUNITY
  management: {
    root: ROOTS.MANAGEMENT,
    user: {
      root: `${ROOTS.MANAGEMENT}/user`,
      account: `${ROOTS.MANAGEMENT}/user/account`,
    },
    community: {
      root: `${ROOTS.MANAGEMENT}/community`,
      profile: `${ROOTS.MANAGEMENT}/community/profile`,
    },
  },
};
