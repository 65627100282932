import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtEmailConifirmationPage = lazy(() => import('src/pages/auth/jwt/confirm-email'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtNewPasswordPage = lazy(() => import('src/pages/auth/jwt/new-password'));

// ----------------------------------------------------------------------

const authJwt = {
  // path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with zCommuna">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

const verificationRoutes = {
  element: (
    <CompactLayout>
      <Outlet />
    </CompactLayout>
  ),
  children: [
    { path: 'verify', element: <JwtVerifyPage /> },
    { path: 'new-password', element: <JwtNewPasswordPage /> },
    { path: 'forgot-password', element: <JwtForgotPasswordPage /> },
    { path: 'confirmation/*', element: <JwtEmailConifirmationPage /> },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt, verificationRoutes],
  },
];
