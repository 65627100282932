export const countries = [
  { code: '', label: '', phone: '' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
];
