import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: { loading: false, notification: { status: '', message: '', count: 0 } },
  reducers: {
    showNotification(state, action) {
      state.notification.status = action.payload.status;
      state.notification.message = action.payload.message;
      state.notification.count += 1;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
