import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  user: icon('ic_user'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Community',
        items: [
          {
            title: 'timeline',
            path: paths.community.root,
            icon: <Iconify icon="solar:clipboard-list-bold-duotone" />,
          },
          {
            title: 'Join Community',
            path: paths.community.join,
            icon: <Iconify icon="solar:people-nearby-bold-duotone" />,
          },
          {
            title: 'Create Community',
            path: paths.community.create,
            icon: <Iconify icon="solar:city-bold-duotone" />,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'user',
            path: paths.management.user.root,
            icon: ICONS.user,
            children: [{ title: 'Account', path: paths.management.user.account }],
          },
          {
            title: 'community',
            path: paths.management.community.root,
            icon: <Iconify icon="solar:city-bold-duotone" />,
            children: [{ title: 'Profile', path: paths.management.community.profile }],
          },
        ],
      },
    ],
    []
  );

  return data;
}
