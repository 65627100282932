export enum COMMUNITYSTATUS {
  Pending = 'Pending',
  GISApproved = 'GISApproved',
  GISError = 'GISError',
  GISDeclined = 'GISDeclined',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Blocked = 'Blocked',
  Abandoned = 'Abandoned',
}

export enum COMMUNITYMEMBESTATUS {
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Blocked = 'Blocked',
  Leaved = 'Leaved',
  Canceled = 'Canceled',
  NotSet = '',
}

export enum COMMUNITYMEMBEROLE {
  Member = 'Member',
  Supervisor = 'Supervisor',
  Admin = 'Admin',
  NotSet = '',
}

export enum COMMUNITYMEMBER {
  CommunityAdmin = 'Community-Admin',
  PendingCommunityAdmin = 'Pending-Community-Admin',
  CommunityMember = 'Community-Member',
  PendingCommunityMember = 'Pending-Community-Member',
  Guest = 'Guest',
  NotSet = '',
}
