import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const PageTimeline = lazy(() => import('src/pages/community/timeline'));
const CreateCommunity = lazy(() => import('src/pages/community/CreateCommunity'));
const JoinCommunity = lazy(() => import('src/pages/community/join-community'));

// ----------------------------------------------------------------------

export const communityRoutes = [
  {
    path: 'community',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      { element: <Navigate to="/community/timeline" replace />, index: true },
      { path: 'timeline', element: <PageTimeline /> },
      { path: 'join', element: <JoinCommunity /> },
      { path: 'create', element: <CreateCommunity /> },
    ],
  },
];
