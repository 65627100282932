import { useEffect, useState } from 'react';
// redux
import { useDispatch } from 'react-redux';
import { authActions } from 'src/store/auth/auth-slice';
import { VISITOR_STATUS } from 'src/enum/auth';
import { isValidToken, clearSessions, setSession } from 'src/utils/auth';
import axios from 'src/utils/axios';
import { SplashScreen } from 'src/components/loading-screen';

type Props = {
  children: React.ReactNode;
};

export default function Initializer({ children }: Props) {
  const dispatch = useDispatch();
  const [InitializerLoaded, setInitializerLoaded] = useState(false);

  useEffect(() => {
    try {
      const getUser = async (accessToken: string, expTime: number) => {
        const response = await axios.post(`/Account/Home`, { accessToken });
        console.log(response);
        const { isOk, message, exception, user } = response.data;
        if (isOk) {
          setSession(accessToken, expTime);
          dispatch(
            authActions.updateState({
              user,
              vistorStatus: VISITOR_STATUS.Authenticated,
            })
          );
        } else {
          dispatch(authActions.updateState({ user: null, vistorStatus: VISITOR_STATUS.Anonymous }));
          clearSessions();
        }
        setInitializerLoaded(true);
      };
      const accessToken = localStorage.getItem('accessToken');
      const expirationTime = localStorage.getItem('expirationTime');
      if (accessToken && expirationTime) {
        const expTime = Number(expirationTime);
        if (isValidToken(expTime)) {
          getUser(accessToken, expTime);
          return;
        }
      }
      dispatch(authActions.updateState({ user: null, vistorStatus: VISITOR_STATUS.Anonymous }));
      setInitializerLoaded(true);
    } catch (error) {
      console.error(error);
      dispatch(authActions.updateState({ user: null, vistorStatus: VISITOR_STATUS.Anonymous }));
    }
  }, [dispatch]);

  return <>{InitializerLoaded ? children : <SplashScreen />}</>;
}
