import { createSlice } from '@reduxjs/toolkit';

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState: {
    allData: {
      phone: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      bio: '',
      isPublic: false,
      address: {
        line1: '',
        line2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
      },
      socialLinks: {
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        linkedinUrl: '',
      },
      notification: {
        ifSomeOneReactOnMyPosts: false,
        ifSomeOneCommentOnMyPosts: false,
        ifSomeOneVisitMyProfile: false,
        ifSomeOneMentionedMe: false,
        ifSomeOneJoinedToCommunity: false,
        ifSomeOnePostOncommunity: false,
      },
    },
  },
  reducers: {
    setUserData(state, action) {
      // type T = keyof typeof state;

      // const keys = Object.keys(action.payload) as [T];
      // keys.forEach((key: 'isPublic' | 'phone') => {
      //   state[key] = action.payload[key];
      // });
      // for (var key in action.payload) {
      //   let dKey: T = key as T;
      //   state[dKey] = action.payload[dKey];
      // }
      state.allData = action.payload;
    },
    updateGeneralAccount(state, action) {
      if (action.payload) {
        state.allData.firstName = action.payload.firstName;
        state.allData.lastName = action.payload.lastName;
        state.allData.bio = action.payload.bio;
        state.allData.isPublic = action.payload.isPublic;
        state.allData.birthDate = action.payload.birthDate;
      }
    },
    updateAddress(state, action) {
      state.allData.address = action.payload;
    },
    updatePhoneNumber(state, action) {
      state.allData.phone = action.payload;
    },
  },
});

export const userAccountActions = userAccountSlice.actions;

export default userAccountSlice.reducer;
