import { createSlice } from '@reduxjs/toolkit';

const communitySlice = createSlice({
  name: 'community',
  initialState: {
    allData: {
      category: '',
      coverPic: '',
      description: '',
      id: '',
      location: { latitude: 0, longitude: 0 },
      membersCount: 0,
      postsCount: 0,
      profilePic: '',
      profileThumbPic: '',
      status: '',
      title: '',
      images: [],
      address: {
        line1: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
      },
    },
  },
  reducers: {
    setcommunityData(state, action) {
      state.allData = action.payload;
    },
    setcommunityAddress(state, action) {
      state.allData.address = action.payload;
    },
  },
});

export const communityActions = communitySlice.actions;

export default communitySlice.reducer;
