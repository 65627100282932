import { IMailingAddress } from 'src/types/app/address';

// ----------------------------------------------------------------------

// Line1-City-Country
export function addressToString(address: IMailingAddress) {
  return `${address.line1}, ${address.city}, ${address.country}`;
}

export function stringToaddress(address: string): IMailingAddress | '' {
  if (address) {
    const addressParts = address.split(',');

    return {
      line1: addressParts[0].trim(),
      line2: '',
      city: addressParts[1].trim(),
      state: addressParts[2].trim(),
      country: addressParts[4].trim(),
      zipCode: addressParts[3].trim(),
    };
  }
  return '';
}
