import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'src/store/auth/auth-slice';
// routes
import { useRouter } from 'src/routes/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { VISITOR_STATUS } from 'src/enum/auth';
// utils
import { clearSessions } from 'src/utils/auth';
import { IUser } from 'src/types/app/user';

// ----------------------------------------------------------------------

const AuthorizedUserLOptions = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/management/user',
  },
  {
    label: 'Communit timeline',
    linkTo: '/community/timeline',
  },
];

const AnonymousUserOptions = [
  {
    label: 'Login',
    linkTo: '/auth/login',
  },
  {
    label: 'Register',
    linkTo: '/auth/register',
  },
  {
    label: 'Join Community',
    linkTo: '/community/join',
  },
];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const user: IUser = useSelector((state: any) => state.auth.user);

  let OPTIONS = AnonymousUserOptions;
  if (user && user.id) {
    OPTIONS = AuthorizedUserLOptions;
  }

  const dispatch = useDispatch();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      clearSessions();
      dispatch(authActions.updateState({ user: null, vistorStatus: VISITOR_STATUS.Anonymous }));
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.profilePic ? user.profilePic : '/assets/icons/home/mainmenu/logo.png'}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        {user && user.id ? (
          <>
            <Box sx={{ p: 2, pb: 1.5 }}>
              <Typography variant="subtitle2" noWrap>
                {user?.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        ) : null}
        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {user && user.id ? (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem
              onClick={handleLogout}
              sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            >
              Logout
            </MenuItem>
          </>
        ) : null}
      </CustomPopover>
    </>
  );
}
