export enum USER_STATUS {
  Active = 'Active',
  Blocked = 'Blocked',
  Deactivated = 'Deactivated',
  Deleted = 'Deleted',
  UnSet = '',
}

export enum GENDER {
  Male = 'Male',
  Female = 'Female',
  UnSet = '',
}
