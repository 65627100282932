// --Arcgis
import { project } from '@arcgis/core/rest/geometryService';
import ProjectParameters from '@arcgis/core/rest/support/ProjectParameters';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
// types
import DrawSetting from 'src/types/gis/draw';
import { _Point } from 'src/types/gis/common';
// data
import Constant from 'src/data/constant';
// --helper
import HelperGeneral from 'src/utils/gis/general';
import HelperGeometry from 'src/utils/gis/geometry';

export default class Draw {
  public static _Draw = (
    pView: __esri.MapView,
    pGraphics: __esri.Graphic[],
    pGraphicsLayer?: __esri.GraphicsLayer,
    pSettings?: DrawSetting
  ) => {
    if (pSettings && pSettings.clearGraphics) {
      if (pGraphicsLayer) {
        Draw.clearGraphics(pGraphicsLayer);
      } else {
        pView.graphics.removeAll();
      }
    }
    pGraphics.forEach((graphic) => {
      if (!graphic.symbol) {
        graphic.symbol = Draw.GetDrawSymbol(graphic.geometry.type);
      }
      if (pGraphicsLayer) {
        pGraphicsLayer.add(graphic);
      } else {
        pView.graphics.add(graphic);
      }
    });

    if (pSettings && pSettings.zoomLevel) {
      let target;
      if (pGraphics.length > 1) {
        target = HelperGeometry.doMerge(
          pGraphics.map((graphic: __esri.Graphic) => graphic.geometry)
        );
      } else {
        target = pGraphics[0].geometry;
      }
      Draw._Zoom(pView, target, pSettings.zoomLevel);
    }
  };

  public static _DrawLatLong = (
    pView: __esri.MapView,
    pPoints: _Point[],
    pGraphicsLayer?: __esri.GraphicsLayer,
    pSettings?: DrawSetting
  ) => {
    console.log('Zoom');
    if (pSettings && pSettings.clearGraphics) {
      if (pGraphicsLayer) {
        Draw.clearGraphics(pGraphicsLayer);
      } else {
        pView.graphics.removeAll();
      }
    }
    const graphics: __esri.Graphic[] = [];
    pPoints.forEach((point) => {
      const graphic = new Graphic({
        symbol: Constant.symbols.draw?.point(16),
        geometry: HelperGeometry.getPoint({
          latitude: point.latitude,
          longitude: point.longitude,
        }),
      });
      graphics.push(graphic);
      if (pGraphicsLayer) {
        pGraphicsLayer.add(graphic);
      } else {
        pView.graphics.add(graphic);
      }
    });

    if (pSettings && pSettings.zoomLevel) {
      let target;
      if (graphics.length > 1) {
        target = HelperGeometry.doMerge(
          graphics.map((graphic: __esri.Graphic) => graphic.geometry)
        );
      } else {
        target = graphics[0].geometry;
      }
      Draw._Zoom(pView, target, pSettings.zoomLevel);
    }
  };

  public static _Zoom = (pView: __esri.MapView, pGeometry: __esri.Geometry, pExpand: number) => {
    if (pGeometry.spatialReference.wkid !== pView.spatialReference.wkid) {
      const params = new ProjectParameters({
        geometries: [pGeometry],
        outSpatialReference: new SpatialReference({
          wkid: pView.spatialReference.wkid,
        }),
      });

      project(Constant.geometryServiceUrl, params).then((evt: any) => {
        Draw._GetProjectedFeatures(pView, pExpand, evt[0]);
      });
    } else {
      Draw._GetProjectedFeatures(pView, pExpand, pGeometry);
    }
  };

  public static _GetProjectedFeatures(pView: __esri.MapView, pExpandValue: number, pGeometry: any) {
    let target: any;
    if (pGeometry.type === 'point') {
      target = HelperGeometry.doBuffer(pGeometry, {
        unit: 'meters',
        width: 250,
      });
    } else {
      target = pGeometry;
    }
    const gExpanded = target.extent.expand(pExpandValue);
    pView.goTo(
      { target: gExpanded },
      {
        animate: true,
        duration: 500,
      }
    );
  }

  public static clearGraphics(pGraphicLayer?: __esri.GraphicsLayer) {
    if (pGraphicLayer) {
      pGraphicLayer.removeAll();
    }
  }

  public static clearGraphicsByName(pView: __esri.View, pGraphicLayerName: string) {
    const gl: __esri.GraphicsLayer = HelperGeneral.getGraphicsLayer(pView, pGraphicLayerName);
    gl.removeAll();
  }

  public static clearAllGraphics(pView?: __esri.View) {
    if (pView) {
      pView.graphics.removeAll();
      pView?.map.allLayers.forEach((pLayer: __esri.Layer) => {
        if (pLayer.type === 'graphics') {
          (pLayer as GraphicsLayer).removeAll();
        }
      });
    }
  }

  public static clearViewGraphics(pView?: __esri.View) {
    if (pView) {
      pView.graphics.removeAll();
    }
  }

  private static GetDrawSymbol(pType: string): any {
    switch (pType) {
      case 'point':
        return Constant.symbols.draw?.point(16);
      case 'polyline':
        return Constant.symbols.draw?.polyline;
      case 'polygon':
        return Constant.symbols.draw?.polygon;
      default:
        break;
    }
    return null;
  }
}
