export enum VERIFICATION_STATUS {
  NotVerified = 'NotVerified',
  Pending = 'Pending',
  Verified = 'Verified',
}

export enum ADDRESS_VERIFICATION_STATUS {
  NotVerified = 'NotVerified',
  Pending = 'Pending',
  Verified = 'Verified',
  GISVerified = 'GisVerified',
  GISDeclined = 'GISDeclined',
  GISError = 'GISError',
  EVerified = 'EVerified',
}

export type MERGED_VERIFICATION_STATUS = VERIFICATION_STATUS | ADDRESS_VERIFICATION_STATUS;
